<template>
  <a-row class="add-candidate">
    <div class="body">
      <div class="steps">
        <div class="steps-content">
          <div class="basic">
            <a-row :gutter="16">
              <a-col :span="12">
                <div class="box-left">
                  <div class="title">Candidate Details</div>
                  <div class="body">
                    <a-form ref="candidateFormRef" :model="candidateForm" :rules="candidateRules" :hideRequiredMark="true" layout="vertical">
                      <a-row :gutter="16">
                        <a-col :span="12">
                          <a-form-item label="First Name" required has-feedback name="first_name">
                            <a-input v-model:value="candidateForm.first_name" type="text" placeholder="Enter First Name" allow-clear autocomplete="off"/>
                          </a-form-item>
                        </a-col>
                        <a-col :span="12">
                          <a-form-item label="Last Name" required has-feedback name="last_name">
                            <a-input v-model:value="candidateForm.last_name" type="text" placeholder="Enter Last Name" allow-clear autocomplete="off"/>
                          </a-form-item>
                        </a-col>
                      </a-row>
                      <a-form-item label="Email" has-feedback name="email">
                        <a-input type="text" placeholder="Enter Email" v-model:value="candidateForm.email" allow-clear autocomplete="off"/>
                      </a-form-item>
                      <a-form-item label="Phone" has-feedback name="phone">
                        <a-input type="number" placeholder="Enter Phone Number" v-model:value="candidateForm.phone" allow-clear autocomplete="off"/>
                      </a-form-item>
                      <a-form-item label="Select Group" has-feedback name="group" v-if="groups">
                        <a-select v-model:value="candidateForm.group" ref="select">
                          <a-select-option :value="group._id" v-for="group in groups" :key="group._id">{{group.name}}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-row :gutter="16">
                        <a-col :span="12">
                          <a-form-item label="Certificate Access" name="certificate_access" class="certificate_access">
                            <a-radio-group name="radioGroup" v-model:value="candidateForm.certificate_access">
                              <a-radio :value="true">Allowed</a-radio>
                              <a-radio :value="false">Not Allowed</a-radio>
                            </a-radio-group>
                          </a-form-item>
                        </a-col>
                        <a-col :span="12">
                          <a-form-item label="Send Login" name="send_login" class="send_login">
                            <a-radio-group name="radioGroup" v-model:value="accessForm.send_login">
                              <a-radio :value="true">Send</a-radio>
                              <a-radio :value="false">Unsend</a-radio>
                            </a-radio-group>
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    <div class="credit-expired" v-if="disableCandidateAdd">
                      <b>Note:</b> Your have used all your subscription, please visit subscription page to buy more subscription.
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="box-right">
                  <div class="title">Courses</div>
                  <div class="body">
                    <div class="body-head-action">
                      <a-button :class="showAllCourse ? 'active-button' : ''" type="primary" @click="listAllCourse">All Courses</a-button>
                      <a-button :class="showGroupCourse ? 'active-button' : ''" type="primary" @click="listGroupCourse">Group Courses</a-button>
                    </div>
                    <div class="all-courses" v-if="showAllCourse">
                      <a-checkbox v-model:checked="selectAllSingleCourse" :indeterminate="indeterminate" @change="onCheckAllSingleCourseChange">
                        All Courses
                      </a-checkbox>
                      <a-checkbox-group v-model:value="selectedCourse" :options="availableCourse"/>
                    </div>
                    <div class="group-courses" v-if="showGroupCourse">
                      <a-checkbox v-model:checked="selectAllGroupCourse" :indeterminate="indeterminate" @change="onCheckAllGroupCourseChange">
                        All Courses
                      </a-checkbox>
                      <a-checkbox-group v-model:value="selectedGroup" :options="availableGroupedCourse"/>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="steps-action">
          <a-button type="danger" size="large" style="margin-left: 8px" @click="next" :loading="loading" :disabled="disableCandidateAdd">
            Finish
          </a-button>
        </div>
      </div>
    </div>
  </a-row>
</template>

<script lang="ts">
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { createVNode, defineComponent, ref, reactive, UnwrapRef, toRefs, onMounted } from 'vue'
import { notification, Modal } from 'ant-design-vue'

import moment from 'moment'

import router from '../../../router'
import validate from '../../../services/validator'
import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import subscriptionService from '../../../services/subscription'
import groupService from '../../../services/candidateGroup'
import commonServices from '../../../services/common'

import ICandidate from '../../../interface/profile'
import IProfileType from '../../../interface/profileType'
import ICourse from '../../../interface/course'

import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'

interface Accessf {
  email: string;
  send_login: boolean;
  certificate_access: boolean;
}
export default defineComponent({
  components: {
  },
  props: ['clientId', 'groupId'],
  setup (props, { emit }) {
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 14, offset: 0 },
        sm: { span: 12, offset: 6 }
      }
    }
    const fromEmail = ref<string>(process.env.VUE_APP_FROM_EMAIL)
    const fromName = ref<string>(process.env.VUE_APP_FROM_NAME)
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const current = ref<number>(0)
    const showAllCourse = ref<boolean>(true)
    const showGroupCourse = ref<boolean>(false)
    const candidate = ref<ICandidate>()
    const profileType = ref<IProfileType>()
    const assignedCourses = ref<Array<ICourse>>([])
    const unassignedCourses = ref<Array<ICourse>>([])
    const availableCourse = ref([])
    const availableGroupedCourse = ref([])
    const groupCourse = ref([])
    const selectedCourseToDisplay = ref([])
    const availableCredit = ref<number>(0)
    const groupId = ref<string>('')
    const disableCandidateAdd = ref<boolean>(false)
    const subscription = ref()
    const steps = [
      {
        title: 'Details',
        slug: 'details'
      },
      {
        title: 'Courses',
        slug: 'courses'
      },
      {
        title: 'Access',
        slug: 'access'
      }
    ]
    const visible = ref<boolean>(false)
    const candidateFormRef = ref()
    const accessFormRef = ref()
    const candidateRules = validate.clientValidation
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const candidateForm: UnwrapRef<ICandidate> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      profile_type: '',
      certificate_access: false,
      created_by: '',
      is_active: true,
      send_login: true,
      status: 'active',
      user_id: '',
      group: ''
    })
    const accessForm: UnwrapRef<Accessf> = reactive({
      email: '',
      send_login: false,
      certificate_access: false
    })
    const groups = ref()
    const loading = ref<boolean>(false)
    const state = reactive({
      indeterminate: false,
      selectAllSingleCourse: false,
      selectAllGroupCourse: false,
      selectedCourse: [],
      selectedGroup: []
    })
    const onCheckAllSingleCourseChange = (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        selectedCourse: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    const onCheckAllGroupCourseChange = (e: any) => {
      const aGroup = []
      for (const group of availableGroupedCourse.value) {
        aGroup.push(group.value)
      }
      Object.assign(state, {
        selectedGroup: e.target.checked ? aGroup : [],
        indeterminate: false
      })
    }
    const listAllCourse = async () => {
      showAllCourse.value = true
      showGroupCourse.value = false
    }
    const listGroupCourse = async () => {
      showGroupCourse.value = true
      showAllCourse.value = false
    }
    const getGroups = async () => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await groupService.getGroup(clientId)
        groups.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getClientprofileType = async () => {
      const responce = await profileService.getProfileType('candidate')
      profileType.value = responce.data
      candidateForm.profile_type = profileType.value._id
    }
    const getAssignedCourse = async () => {
      try {
        const responce = await courseService.getAssignedCourse(candidate.value._id)
        assignedCourses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const assignCourse = async () => {
      try {
        const lSubscription = commonServices.getCurrentSubscription()
        const enroll = []
        loading.value = true
        for (const courseId of state.selectedCourse) {
          let expiryDate = ''
          const course = unassignedCourses.value.filter(course => course._id === courseId)
          if (course.length > 0) {
            if (course[0].validity > 0) {
              expiryDate = moment().add(course[0].validity, 'months').format()
            }
          }
          enroll.push({ enrolled_date: new Date(), profile: candidate.value._id, course: courseId, expiry_date: expiryDate, subscription: lSubscription._id })
        }
        console.log('state', state)
        for (const groupId of state.selectedGroup) {
          const group = groupCourse.value.filter(group => group._id === groupId)
          console.log('group', group)
          if (group.length > 0) {
            for (const course of group[0].courses) {
              const courseDuplicate = enroll.filter(e => e.course === course._id)
              if (courseDuplicate.length === 0) {
                let expiryDate = ''
                if (course.validity > 0) {
                  expiryDate = moment().add(course.validity, 'months').format()
                }
                enroll.push({ enrolled_date: new Date(), profile: candidate.value._id, course: course._id, expiry_date: expiryDate, subscription: lSubscription._id })
              }
            }
          }
        }
        if (lSubscription.type === 'Credit') {
          const subscribedCourses = await subscriptionService.getSubscribedCourses(lSubscription._id)
          const subscribedCourseCount = subscribedCourses.data.length + enroll.length
          console.log(lSubscription.quantity, subscribedCourseCount)
          if (lSubscription.quantity < subscribedCourseCount) {
            loading.value = false
            notify('Warning', 'You dont have enough credit, please update your credit.', 'warning')
            return
          }
          lSubscription.available_credit = (lSubscription.available_credit - enroll.length)
          const responce = await subscriptionService.updateSubscription(lSubscription._id, lSubscription)
          localStorage.subscription = JSON.stringify(responce.data)
        }
        await courseService.enrollMultipleCourse(enroll)
        loading.value = false
        // closeCourseListModal()
        getAssignedCourse()
        // selectedCourseToDisplay.value = []
        // for (const course of state.selectedCourse) {
        //   selectedCourseToDisplay.value.push(availableCourse.value.filter(c => c.value === course)[0])
        // }
        notify('Success', 'Course Assigned Successfully', 'success')
        router.push(`/client/candidates${loginAs.value}`)
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const sendEmailToCandidate = async () => {
      try {
        // candidate.value.certificate_access = accessForm.certificate_access
        // await profileService.updateProfile(candidate.value._id, candidate.value)
        if (candidateForm.send_login) {
          const data = {
            from: `${fromEmail.value} <${fromName.value}>`,
            to: candidate.value.email,
            subject: 'Your account has been created',
            templateId: 'new-candidate-create',
            content: {
              user_name: candidate.value.email,
              password: candidate.value.password,
              full_name: `${candidate.value.first_name} ${candidate.value.last_name}`
            }
          }
          await profileService.sendEmail(data)
          notify('Success', 'Candidate Account Created Successfully', 'success')
        }
        const profile = commonServices.getCurrentProfile()
        if (profile.profile_type.slug === 'agency') {
          emit('refreshCandidateList', {})
        } else {
          if (props.groupId) {
            router.push(`/client/candidate_group/add/${props.groupId}${loginAs.value}`)
          } else {
            router.push(`/client/candidates${loginAs.value}`)
          }
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const createCandidate = async (values: ICandidate) => {
      try {
        const lsubscription = commonServices.getCurrentSubscription()
        availableCredit.value = +lsubscription.available_credit
        subscription.value = lsubscription
        loading.value = true
        const responce = await profileService.createNewCandidate(values, props.clientId)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          candidate.value = responce.data
          candidateForm.user_id = candidate.value.user_id
          assignCourse()
          if (groupId.value) {
            await groupService.addCandidates({ enabled: true, candidate: candidate.value._id, group: groupId.value })
          }
          // Reduice credit
          if (subscription.value.type === 'Subscription') {
            subscription.value.available_credit = (subscription.value.available_credit - 1)
            const responce = await subscriptionService.updateSubscription(subscription.value._id, subscription.value)
            subscription.value = responce.data
            availableCredit.value = (availableCredit.value - 1)
            localStorage.subscription = JSON.stringify(responce.data)
            if (availableCredit.value <= 0) {
              disableCandidateAdd.value = true
            }
          }
          current.value++
        }
        loading.value = false
      } catch (error) {
        console.log('error', error)
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateCandidate = async (values: ICandidate) => {
      try {
        loading.value = true
        const responce = await profileService.updateProfile(candidate.value._id, values)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          candidate.value = responce.data
          current.value++
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const getGroupCourses = async () => {
      try {
        availableGroupedCourse.value = []
        const clientId = commonServices.getClientId()
        const responce = await courseService.getGroupCourse(clientId)
        groupCourse.value = responce.data
        for (const group of groupCourse.value) {
          availableGroupedCourse.value.push({ label: group.name, value: group._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getUnassignedCourses = async () => {
      try {
        availableCourse.value = []
        let candidateId = null
        if (candidate.value) {
          candidateId = candidate.value._id
        }
        const responce = await courseService.getCandidateUnAssignedCourse(props.clientId, candidateId)
        unassignedCourses.value = responce.data
        for (const course of unassignedCourses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const unAssignCourse = async (id, index) => {
      try {
        await courseService.unAssignCourse(id)
        assignedCourses.value.splice(index, 1)
        getUnassignedCourses()
        notify('Success', 'Course Unassigned Successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showDeleteConfirm = async (id, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          unAssignCourse(id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const next = async () => {
      candidateFormRef.value.validate().then(() => {
        if (candidate.value && candidate.value._id) {
          updateCandidate(candidateForm)
        } else {
          if (candidateForm.group) {
            groupId.value = candidateForm.group
          }
          createCandidate(candidateForm)
        }
      }).catch((error: ValidateErrorEntity<ICandidate>) => {
        console.log('error', error)
      })
    }
    onMounted(() => {
      groupId.value = props.groupId
      const lSubscription = commonServices.getCurrentSubscription()
      availableCredit.value = +lSubscription.available_credit
      subscription.value = lSubscription
      if (subscription.value.type === 'Subscription' && availableCredit.value <= 0) {
        disableCandidateAdd.value = true
      }
      getClientprofileType()
      getGroups()
      getUnassignedCourses()
      getGroupCourses()
    })
    return {
      formItemLayoutWithOutLabel,
      candidateRules,
      candidateFormRef,
      steps,
      current,
      notify,
      candidateForm,
      loading,
      next,
      visible,
      ...toRefs(state),
      accessFormRef,
      accessForm,
      sendEmailToCandidate,
      onCheckAllSingleCourseChange,
      onCheckAllGroupCourseChange,
      availableCourse,
      assignCourse,
      selectedCourseToDisplay,
      disableCandidateAdd,
      showDeleteConfirm,
      getUnassignedCourses,
      getAssignedCourse,
      assignedCourses,
      availableGroupedCourse,
      groups,
      showGroupCourse,
      showAllCourse,
      listAllCourse,
      listGroupCourse
    }
  }
})
</script>

<style lang="scss">
.add-candidate {
  padding: 10px;
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    .steps {
      min-height: 100px;
      padding-top: 20px;
      width: 100%;
      .ant-steps {
        background: #ffffff;
        border-radius: 10px;
        padding: 20px 100px;
        .ant-steps-item {
          margin-right: 0;
          text-align: left;
          .ant-steps-item-icon {
            display: none;
          }
          .ant-steps-item-title {
            border: 1px solid #ffc788;
            border-radius: 20px;
            width: 100px;
            font-size: 14px;
            font-family: "TT Norms Pro Medium";
            text-align: center;
            padding-right: 0;
          }
        }
        .ant-steps-item-wait {
          .ant-steps-item-title {
            color: #646464;
          }
        }
        .ant-steps-item-finish,
        .ant-steps-item-process {
          .ant-steps-item-title {
            background: #ffc788;
            color: #ffffff;
          }
        }
        .ant-steps-item-finish {
          .ant-steps-item-title::after {
            background-color: #ffc788;
          }
        }
      }
      .steps-content {
        margin-top: 16px;
        background: #ffffff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        min-height: 275px;
        text-align: center;
        padding-top: 20px;
        .basic {
          .box-left {
            .title {
              text-align: left;
              background: #FF6A7F;
              height: 50px;
              border-top-right-radius: 20px;
              border-top-left-radius: 20px;
              padding: 15px;
              font-weight: bold;
              color: #ffffff;
            }
            .body {
              background: #F8FCFF;
              border: 1px solid #E6E6E6;
              border-radius: unset;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
              .certificate_access {
                .ant-form-item-control-wrapper {
                  text-align: left;
                }
              }
              .send_login {
                .ant-form-item-control-wrapper {
                  text-align: left;
                }
              }
            }
          }
          .box-right {
            .title {
              text-align: left;
              background: #FFC788;
              height: 50px;
              border-top-right-radius: 20px;
              border-top-left-radius: 20px;
              padding: 15px;
              font-weight: bold;
              color: #ffffff;
            }
            .body {
              background: #72CF9717;
              border: 1px solid #E6E6E6;
              border-radius: unset;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
              min-height: 451px;

              .body-head-action {
                text-align: left;
                padding-bottom: 20px;
                border-bottom: 1px solid #D1D1D1;
                button {
                  margin-right: 20px;
                  background: #72CF97;
                  border-color: #72CF97;
                  border-radius: 5px;
                  width: 120px;
                }
                .active-button {
                  background: #2ECC71;
                  border-color: #2ECC71;
                }
              }
              .group-courses,
              .all-courses {
                margin-top: 15px;
              }

              .ant-checkbox-wrapper,
              .ant-checkbox-group {
                width: 100%;
                text-align: left;
                margin-bottom: 5px;
              }
            }
          }
          .ant-form {
            margin: auto;
          }
          .ant-form-vertical {
            .ant-select {
              text-align: left;
            }
            .ant-form-item {
              padding-bottom: 0 !important;
            }
          }
        }
        .out-comes {
          .ant-form-item-control {
            text-align: left;
            input {
              width: 90%;
              margin-right: 10px;
            }
          }
          .selectcourselist {
            text-align: left;
            width: 500px;
            margin: 0 auto;
            h4 {
              font-size: 16px;
              text-transform: capitalize;
              color: #646464;
              font-family: "TT Norms Pro Medium";
              margin: 0;
              text-align: left;
              padding: 0 0 5px 0;
              border-bottom: 1px solid #d9d9d9;
            }
            ul {
              list-style: none;
              text-align: left;
              padding-left: 10px;
              li {
                margin: 2px 0;
              }
            }
          }
          .action {
            button {
              border: 1px solid #2ecc71;
              background: transparent;
              border-radius: 20px;
              box-shadow: none;
              span {
                color: #646464;
                font-family: "TT Norms Pro Regular";
              }
              .anticon {
                color: #2ecc71;
              }
            }
          }
        }
      }
      .finish {
        .ant-form {
          width: 500px;
          margin: auto;
          .sendlogin {
            margin: 0;
            .ant-form-item-control {
              width: 300px;
              margin: auto;
            }
            .ant-input-affix-wrapper-disabled {
              background-color: transparent;
            }
          }
        }
        .ant-select {
          text-align: left;
        }
        .ant-col-offset-6 {
          margin-left: 25% !important;
        }
        .ant-form-item {
          padding-bottom: 0 !important;
        }
        .ant-form-item-label {
          text-align: center;
          font-family: "TT Norms Pro Medium";
          label {
            color: #646464;
          }
        }

        .ant-radio-checked .ant-radio-inner {
          border-color: #38B6FF;
        }
        .ant-radio-checked::after {
          border: 1px solid #38B6FF;
        }
        .ant-radio-inner::after {
          background-color: #38B6FF;
        }
        .action {
          button {
            background: #2ecc71;
            border: 1px solid #2ecc71;
            border-radius: 5px;
            width: 100px;
          }
        }
      }
      .steps-action {
        background: #ffffff;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 20px 0px;
        float: right;
        .ant-btn-danger {
          background-color: #72CF97;
          border-color: #72CF97;
          width: 125px;
          border-radius: 10px;
        }
      }
    }
    .ant-form-explain {
      text-align: left;
    }
  }
}

</style>
